import React from 'react';
import { AppearSensor } from 'components/AppearSensor';
import { Animation } from 'components/Animation';

export const AdvantageBlock = () => (
  <section className="advantage-block container">
    <h2 className="block-title">
      Преимущества занятий в современной онлайн-школе UP! Sсhool
    </h2>
    <AppearSensor>
      <div className="advantage-row">
        <div className="advantage-item">
          <Animation visible delay={200} className="number-btn">
            <span>1</span>
          </Animation>
          <div className="text">
            Есть возможность заниматься прямо из дома или в любом другом удобном
            месте, благодаря чему вы экономите время на дорогу
          </div>
        </div>
        <div className="advantage-item">
          <Animation visible delay={600} className="number-btn">
            <span>2</span>
          </Animation>
          <div className="text">
            Индивидуальный подход при подборе направлений и составлении
            программы обучения
          </div>
        </div>
        <div className="advantage-item">
          <Animation visible delay={1000} className="number-btn">
            <span>3</span>
          </Animation>
          <div className="text">
            Регулярная обратная связь от тренера об успехах вашего ребенка
          </div>
        </div>
        <div className="advantage-item">
          <Animation visible delay={1400} className="number-btn">
            <span>4</span>
          </Animation>
          <div className="text">
            Административная поддержка на всех этапах обучения
          </div>
        </div>
      </div>
    </AppearSensor>
  </section>
);
