import React from 'react';
import { SEO } from 'components/SEO';
import { MainBlock } from './MainBlock';
import { CoursesBlock } from './CoursesBlock';
import { FormBlock } from './FormBlock';
import { AdvantageBlock } from './AdvantageBlock';
import { StudentGoalsBlock } from './StudentGoalsBlock';
import { LeadModal } from 'containers/Modals/LeadModal';
import './styles.scss';

const Home = () => {
  const [leadModalOpened, setLeadModalOpened] = React.useState(false);
  const onLeadModalClose = React.useCallback(() => {
    setLeadModalOpened(false);
  }, []);
  const onLeadModalOpen = React.useCallback(() => {
    setLeadModalOpened(true);
  }, []);

  return (
    <main className="page home-page">
      <SEO
        landing
        title="UP! School | онлайн-школа для детей и подростков"
        description="Развивающая онлайн-школа для детей от 6 лет. Обучим вашего ребенка: программированию, шахматам, математике. Бесплатные пробные занятия."
      />
      <MainBlock onActionBtnClick={onLeadModalOpen} />
      <AdvantageBlock />
      <StudentGoalsBlock />
      <CoursesBlock />
      <FormBlock />
      <LeadModal opened={leadModalOpened} onClose={onLeadModalClose} />
    </main>
  );
};

export default Home;
