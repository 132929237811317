import React from 'react';
import { LocationContext } from 'containers/Location/LocationContext';
import sideBackgroundImage from './images/side.png';
import { FormCard } from './FormCard';

export const FormBlock = () => {
  const location = React.useContext(LocationContext);
  return (
    <section className="form-block container">
      <h2 className="title">
        Хотите заниматься в нашей онлайн школе, но не знаете что выбрать?
      </h2>
      <div className="form-wrapper">
        <img
          className="form-side-img"
          src={sideBackgroundImage}
          alt="Люди с вопросительными знаками"
        />
        <FormCard location={location} />
      </div>
    </section>
  );
};
