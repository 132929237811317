import React from 'react';
import { Form, FormikProps, withFormik } from 'formik';
import { FormInput } from 'components/FormInput';
import { PhoneInput } from 'components/PhoneInput';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import { apiCallErrorWithMessage, apiCallSuccessWithMessage } from 'api/utils';
import { apiMessages } from 'api/requests/messages';
import { createLeadRequest } from 'api/requests/createLeadRequest';
import { ILocation } from 'containers/Location/types';
import { ButtonSpinner } from 'components/ButtonSpinner';
import { IFormValues } from './types';
import Alert from 'react-bootstrap/Alert';

interface IProps {
  location: ILocation;
}

const FormCardInner = ({ isSubmitting, status }: FormikProps<IFormValues>) => (
  <div className="form-card">
    <h4 className="form-title">
      Оставьте контактные данные и мы обязательно проконсультируем вас
    </h4>
    <Form>
      {status && (
        <Alert variant="danger" className="text-center">
          {status}
        </Alert>
      )}
      <FormInput<keyof IFormValues>
        name="name"
        title="Имя"
        placeholder="Введите ваше имя"
      />
      <FormInput<keyof IFormValues>
        type="email"
        name="email"
        title="Email"
        placeholder="Введите ваш email"
      />
      <PhoneInput<keyof IFormValues>
        name="phoneNumber"
        title="Номер телефона"
      />
      <Button
        disabled={isSubmitting}
        type="submit"
        className="action-btn btn-shine"
        variant="secondary"
        size="lg"
      >
        {isSubmitting ? <ButtonSpinner /> : `Получить консультацию`}
      </Button>
    </Form>
  </div>
);

const withForm = withFormik<IProps, IFormValues>({
  mapPropsToValues: () => ({
    name: '',
    email: '',
    phoneNumber: '',
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    phoneNumber: Yup.string().required(),
  }),
  handleSubmit: (
    values,
    { setSubmitting, setErrors, setStatus, resetForm, props },
  ) => {
    createLeadRequest({
      email: values.email,
      name: values.name,
      phoneNumber: values.phoneNumber,
      country: props.location.country,
      city: props.location.city,
      timezone: props.location.timezone,
    })
      .then(
        apiCallSuccessWithMessage(setSubmitting, () => {
          import('sweetalert2').then((Swal) => {
            Swal.default.fire({
              title: 'Заявка отправлена!',
              text: apiMessages.lead.success,
              icon: 'success',
              confirmButtonText: 'ОК',
            });
          });
          resetForm();
        }),
      )
      .catch(apiCallErrorWithMessage(setSubmitting, setErrors, setStatus));
  },
});

export const FormCard = withForm(FormCardInner);
