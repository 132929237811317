import React from 'react';
import { AppearSensor } from 'components/AppearSensor';
import { Animation } from 'components/Animation';
import { AppearAndScale } from './animation';

export const BackgroundImg = () => {
  return (
    <AppearSensor offset={100}>
      <div className="background-img-container">
        <Animation
          delay={200}
          duration={400}
          animation={AppearAndScale}
          className="goal-text"
        >
          Получение шахматного разряда
        </Animation>
        <Animation
          delay={500}
          duration={400}
          animation={AppearAndScale}
          className="goal-text"
        >
          Разработка собственной игры
        </Animation>
        <Animation
          delay={800}
          duration={400}
          animation={AppearAndScale}
          className="goal-text"
        >
          Работа в профессии с высокой зарплатой
        </Animation>
        <Animation
          delay={1100}
          duration={400}
          animation={AppearAndScale}
          className="goal-text"
        >
          Завоевание призовых мест на олимпиадах
        </Animation>
        <Animation
          delay={1400}
          duration={400}
          animation={AppearAndScale}
          className="goal-text"
        >
          Создание сайта или лендинга
        </Animation>
      </div>
    </AppearSensor>
  );
};
