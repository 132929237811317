import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'gatsby';
import { routes } from 'routes';
import programmingImage from './images/programming.png';
import chessImage from './images/chess.png';

export const CoursesBlock = () => (
  <section className="courses-block container">
    <h2 className="title">Направления обучения</h2>
    <div className="course-list">
      <div className="course-item">
        <Link to={routes.programming.path}>
          <img
            className="course-img"
            src={programmingImage}
            alt="Программирование"
          />
        </Link>
        <div className="course-description">
          <h4 className="course-title">Программирование</h4>
          <div className="course-subtitle">- от 6 до 16 лет</div>
          <Link to={routes.programming.path}>
            <Button size="lg" variant="secondary">
              Подробнее
            </Button>
          </Link>
        </div>
      </div>
      <div className="course-item">
        <a href="https://chess-up.school">
          <img className="course-img" src={chessImage} alt="Шахматы" />
        </a>
        <div className="course-description">
          <h4 className="course-title">Шахматы</h4>
          <div className="course-subtitle">- от 6 до 16 лет</div>
          <a href="https://chess-up.school">
            <Button size="lg" variant="secondary">
              Подробнее
            </Button>
          </a>
        </div>
      </div>
    </div>
  </section>
);
