import React from 'react';
import Button from 'react-bootstrap/Button';
import { BackgroundImg } from './BackgroundImg';

interface IProps {
  onActionBtnClick: () => void;
}

export const MainBlock = ({ onActionBtnClick }: IProps) => (
  <section className="main-block container">
    <div className="background-top" />
    <div className="main-proposition">
      <h1 className="title">Онлайн школа для детей и подростков</h1>
      <div className="subtitle">
        Подарим ребёнку уверенное будущее уже сейчас
      </div>
      <div className="info-list">
        <div className="info-item">
          от 6<br /> до 16 лет
        </div>
        <div className="info-item">в удобное время</div>
        <div className="info-item">с опытными учителями</div>
      </div>
      <Button
        variant="secondary"
        size="lg"
        className="btn-shine"
        onClick={onActionBtnClick}
      >
        Начать учиться
      </Button>
    </div>
    <BackgroundImg />
  </section>
);
