import { keyframes } from '@emotion/core';

export const AppearAndScale = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.01);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
